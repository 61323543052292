import React from 'react';
import { AppContext } from '../lib/app-context';

export default function Webinars() {
  const { config } = React.useContext(AppContext);
  React.useEffect(() => {
    if (config) {
      window.location.assign(`${config.NEW_UI_URL}/webinars`);
    }
  }, [config]);
  return <div />;
}
